<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on" @click.prevent="$router.push('/admin')">
          <v-icon size="30"> mdi-arrow-left </v-icon>
        </v-btn>
      </template>
      <span>{{ $i18n.t("TABLE.back") }}</span>
    </v-tooltip>
    <Table
      :data="companies"
      :headers="headers"
      :loading="loading"
      :icon="'mdi-domain'"
      @save="showSave"
      @add="showAdd"
      @delete="showDelete"
      :companies="[]"
      :accept-companies="false"
    />

    <v-dialog
      v-model="dialogs.add"
      persistent
      width="1000px"
      overlay-opacity="0.5"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
    >
      <v-card>
        <v-tabs vertical id="campaign">
          <v-tab
            v-for="setting in companySettings"
            :key="setting.name"
            style="justify-content: start; color: white"
          >
            <v-icon class="pr-2 pb-1" color="white">
              {{ setting.icon }}
            </v-icon>

            <template v-if="setting.icon == 'mdi-border-color'">
              {{ $i18n.t("COMPANY.DISPOSITIONS") }}
            </template>
            <label v-else>{{ setting.name }}</label>
          </v-tab>
          <v-btn
            depressed
            :loading="buttonLoader"
            color="red"
            dark
            @click.prevent="dialogs.delete = true"
          >
            {{ $i18n.t("GENERAL.delete") }}
          </v-btn>
          <v-tab-item>
            <v-card class="save-card px-8 pb-4 pt-8">
              <v-card-text class="pt-6">
                <v-row no-gutters class="px-2">
                  <v-col cols="12" class="px-2">
                    <label
                      v-show="dialogs.save == true && !dialogs.add == false"
                      style="position: absolute; right: 10px; top: -40px"
                      ><v-icon>mdi-account</v-icon>
                      {{
                        $i18n.t("COMPANY.TOTALAGENTS") + company.actualAgents
                      }}
                    </label>

                    <v-form ref="add-form" lazy-validation>
                      <v-text-field
                        filled
                        dense
                        rounded
                        v-model="company.id"
                        :rules="[rules.required, rules.notExist]"
                        placeholder="ex: Integra"
                        prepend-icon="mdi-identifier"
                        :label="$i18n.t('COMPANY.idLabel')"
                        :disabled="
                          dialogs.save == true && !dialogs.add == false
                        "
                      />
                      <v-text-field
                        filled
                        dense
                        rounded
                        v-model="company.name"
                        :rules="[rules.required]"
                        prepend-icon="mdi-account"
                        :label="$i18n.t('COMPANY.nameLabel')"
                      />
                      <v-row no-gutters>
                        <v-col cols="6" class="pr-2">
                          <v-autocomplete
                            :rules="[rules.required]"
                            v-model="company.country"
                            :items="itemsCountry"
                            dense
                            prepend-icon="mdi-web"
                            filled
                            rounded
                            :label="$i18n.t('COMPANY.countryLabel')"
                          />
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-select
                            filled
                            dense
                            rounded
                            v-model="company.hourFormat"
                            :items="['12', '24']"
                            prepend-icon="mdi-clock-time-eight-outline"
                            :label="$i18n.t('SETTING.time')"
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-textarea
                            filled
                            auto-grow
                            rows="1"
                            dense
                            rounded
                            v-model="company.description"
                            prepend-icon="mdi-card-text"
                            :label="$i18n.t('COMPANY.descriptionLabel')"
                          />
                        </v-col>
                      </v-row>
                      <div
                        v-if="this.$store.state.Auth.token.role === 'superuser'"
                      >
                        <v-row
                          no-gutters
                          v-if="
                            this.$store.state.Auth.token.role === 'superuser'
                          "
                        >
                          <v-col cols="6" class="pr-2">
                            <v-text-field
                              filled
                              dense
                              rounded
                              type="number"
                              min="1"
                              :rules="[rules.required, rules.minAgents]"
                              v-model="company.maxAgents"
                              prepend-icon="mdi-account"
                              :label="$i18n.t('COMPANY.MAXAGENTS')"
                            />
                          </v-col>
                          <v-col cols="6" class="pl-2">
                            <v-autocomplete
                              :rules="[rules.required]"
                              v-model="company.tenant"
                              :items="itemsTenant"
                              dense
                              item-text="text"
                              item-value="id"
                              prepend-icon="mdi-currency-usd"
                              filled
                              rounded
                              :label="$i18n.t('COMPANY.TENANT')"
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-text-field
                              filled
                              dense
                              rounded
                              v-model="company.billingId"
                              prepend-icon="mdi-currency-usd"
                              :label="$i18n.t('COMPANY.billingIdLabel')"
                              disabled
                            />
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <span
                              class="caption legend-usage-reached"
                              v-if="usageLimitReached"
                            >
                              {{
                                $i18n.t(
                                  "COMPANY.monthlyUsageReachedBannerCompany"
                                )
                              }}
                            </span>
                            <v-text-field
                              filled
                              dense
                              rounded
                              hide-details
                              v-model="company.secondsLimitPerCompany"
                              :rules="[rules.positiveValues]"
                              prepend-icon="mdi-clock-time-eight"
                              :suffix="$i18n.t('COMPANY.inMinutes')"
                              :label="$i18n.t('COMPANY.accountUsageLimit')"
                            />
                            <span class="caption legend-unlimited-usage">
                              {{ $i18n.t("COMPANY.noUsageLimitAdded") }}
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end">
                    <v-btn text class="mr-1" @click="closeAdd">
                      {{ $i18n.t("GENERAL.close") }}
                    </v-btn>
                    <v-btn
                      v-if="dialogs.add && !dialogs.save"
                      depressed
                      color="green"
                      dark
                      @click.prevent="add"
                      :disabled="disableSave"
                    >
                      {{ $i18n.t("GENERAL.add") }}
                    </v-btn>
                    <v-btn
                      v-else
                      depressed
                      color="green"
                      dark
                      @click.prevent="save"
                      :disabled="disableSave"
                    >
                      {{ $i18n.t("GENERAL.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center"
              >
                {{ $i18n.t("COMPANY.WEBHOOK") }}
              </v-card-title>
              <v-card-text class="pt-3">
                <v-form ref="add-form" lazy-validation>
                  <v-text-field
                    filled
                    dense
                    rounded
                    v-model="callback.url"
                    prepend-icon="mdi-account"
                    :label="$i18n.t('COMPANY.endpointUrl')"
                  />
                  <v-text-field
                    type="password"
                    filled
                    dense
                    rounded
                    v-model="callback.secret"
                    prepend-icon="mdi-lock"
                    :label="$i18n.t('COMPANY.secret')"
                  />
                  <v-switch
                    v-model="callback.enabled"
                    :label="$i18n.t('COMPANY.tokenTableHeaders.enabled')"
                    color="primary"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end">
                    <v-btn text class="mr-1" @click="closeAdd">
                      {{ $i18n.t("GENERAL.close") }}
                    </v-btn>
                    <v-btn
                      color="green"
                      dark
                      class="mr-1"
                      @click="saveCallback"
                    >
                      {{ $i18n.t("GENERAL.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center"
              >
                Api Token
              </v-card-title>
              <v-card-text>
                <v-data-table
                  dense
                  :headers="headersToken"
                  :items="this.apitoken"
                  item-key="id"
                  full-width
                  :loading="apiloading"
                >
                  <template v-slot:item.id="{ item }">
                    <label style="display: inline-block; line-break: anywhere">
                      {{ item.id }}
                    </label>
                  </template>
                  <template v-slot:item.enabled="{ item }">
                    <v-switch v-model="item.enabled" />
                  </template>
                  <template v-slot:item.test="{ item }">
                    <v-btn
                      color="primary"
                      dark
                      elevation="0"
                      @click="testToken(item.id)"
                    >
                      {{ $i18n.t("COMPANY.test") }}
                    </v-btn>
                  </template>
                </v-data-table>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end">
                    <v-btn
                      color="green"
                      dark
                      class="mr-1"
                      @click="updateTokens"
                    >
                      {{ $i18n.t("GENERAL.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-btn text class="mr-1" @click="showTokenCreation">
                  {{ $i18n.t("COMPANY.NEWTOKEN") }}
                </v-btn>
                <div v-if="showCreation">
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field
                        filled
                        dense
                        rounded
                        v-model="newtoken.name"
                        :label="$i18n.t('COMPANY.TOKENNAME')"
                      />
                    </v-col>
                    <v-col>
                      <v-menu
                        ref="menu"
                        v-model="tokenmenu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            filled
                            rounded
                            hide-details
                            clearable
                            v-model="newtoken.expiration_date"
                            :label="$i18n.t('COMPANY.TOKENEXPIRATION')"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="newtoken.expiration_date"
                          :min="today"
                          no-title
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" style="text-align: end">
                      <v-btn
                        color="green"
                        dark
                        class="mr-1"
                        @click="createToken"
                      >
                        {{ $i18n.t("COMPANY.CREATETOKEN") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end">
                    <v-btn text class="mr-1" @click="closeAdd">
                      {{ $i18n.t("GENERAL.close") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>

          <div v-if="false">
            <v-tab-item>
              <v-card flat class="px-8 py-6 card__content-size">
                <v-card-title
                  class="pa-0 pb-4 pl-5"
                  style="justify-content: center"
                >
                  {{ $i18n.t("COMPANY.keywords") }}
                </v-card-title>
                <v-card-text class="pt-3">
                  <v-row>
                    <v-col style="display: flex; align-items: baseline">
                      <v-text-field
                        filled
                        rounded
                        :label="$i18n.t('COMPANY.ADDKEYWORD')"
                        v-model="keyword"
                        class="pr-3"
                        @keyup.enter="addKeyword"
                      />
                      <v-btn
                        depressed
                        color="green"
                        dark
                        class="pl-3"
                        @click.prevent="addKeyword"
                      >
                        {{ $i18n.t("GENERAL.add") }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-chip-group column>
                    <v-chip
                      :close="true"
                      class="pr-3"
                      v-for="word in company.keywords"
                      :key="word"
                      @click:close="removeKeyword(word)"
                    >
                      {{ word }}
                    </v-chip>
                  </v-chip-group>

                  <!-- Notifications -->
                  <v-card-title class="pa-0 pb-4 pl-5 pt-5">
                    {{ $i18n.t("COMPANY.notificationEmails") }}
                  </v-card-title>
                  <v-row>
                    <v-col style="display: flex; align-items: baseline">
                      <v-text-field
                        filled
                        rounded
                        :label="$i18n.t('COMPANY.email')"
                        v-model="email"
                        class="pr-3"
                        @keyup.enter="addEmail"
                      />
                      <v-btn
                        depressed
                        color="green"
                        dark
                        class="pl-3"
                        @click.prevent="addEmail"
                      >
                        {{ $i18n.t("GENERAL.add") }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-chip-group column>
                    <v-chip
                      :close="true"
                      class="pr-3"
                      v-for="notifEmail in company.emailNotifications"
                      :key="notifEmail"
                      @click:close="removeEmail(notifEmail)"
                    >
                      {{ notifEmail }}
                    </v-chip>
                  </v-chip-group>
                  <v-switch
                    v-model="company.allAdminEmailNotifications"
                    :label="$i18n.t('COMPANY.allAdminEmails')"
                    color="primary"
                    @click="
                      onAllAdminsActive(company.allAdminEmailNotifications)
                    "
                  />
                  <p class="caption">
                    {{ $i18n.t("COMPANY.allEmailsCaption") }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-row no-gutters>
                    <v-col cols="12" style="text-align: end">
                      <v-btn text class="mr-1" @click="closeAdd">
                        {{ $i18n.t("GENERAL.close") }}
                      </v-btn>
                      <v-btn
                        v-if="dialogs.add && !dialogs.save"
                        depressed
                        color="green"
                        dark
                        @click.prevent="add"
                      >
                        {{ $i18n.t("GENERAL.add") }}
                      </v-btn>
                      <v-btn
                        v-else
                        depressed
                        color="green"
                        dark
                        @click.prevent="save"
                      >
                        {{ $i18n.t("GENERAL.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </div>

          <v-tab-item>
            <v-card flat class="px-8 py-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center"
              >
                {{ $i18n.t("COMPANY.STEREO") }}
              </v-card-title>
              <v-card-text class="pt-3">
                <v-select
                  :label="$i18n.t('COMPANY.INBOUNDCHANNEL')"
                  :items="['Agent Left', 'Agent Right']"
                  filled
                  rounded
                  v-model="audio.inboundChannel"
                  prepend-icon="mdi-call-received"
                />

                <v-select
                  :label="$i18n.t('COMPANY.OUTBOUNDCHANNEL')"
                  :items="['Agent Left', 'Agent Right']"
                  filled
                  rounded
                  v-model="audio.outboundChannel"
                  prepend-icon="mdi-call-made"
                />
              </v-card-text>
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end">
                    <v-btn text class="mr-1" @click="closeAdd">
                      {{ $i18n.t("GENERAL.close") }}
                    </v-btn>
                    <v-btn
                      color="green"
                      dark
                      class="mr-1"
                      @click="saveAudioSettings"
                    >
                      {{ $i18n.t("GENERAL.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="this.$store.state.Auth.token.role === 'superuser'">
            <v-card flat class="px-2 pt-6 card__content-size">
              <v-card-title
                class="pa-0 pb-4 pl-5"
                style="justify-content: center"
              >
                {{ $i18n.t("COMPANY.USAGE") }}
              </v-card-title>
              <v-card-text class="pb-0">
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          filled
                          rounded
                          hide-details
                          v-model="dateRangeText"
                          :label="$i18n.t('dateRange')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="dates"
                        :min="dates && dates.length == 1 ? dates[0] : null"
                        range
                        no-title
                      >
                        <v-spacer />
                        <v-btn text color="primary" @click="menu = false">
                          {{ $i18n.t("GENERAL.cancel") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menu.save(dates);
                            getReport();
                          "
                        >
                          Ok
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row v-show="nodata" class="align-center">
                  <v-col cols="12" class="text-center">
                    <v-card height="400" elevation="0">
                      <label
                        class="text-h5"
                        style="margin-top: 20%; display: inline-block"
                        >{{ $i18n.t("TABLE.noResult") }}</label
                      >
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="align-center justify-center"
                  v-show="!nodata"
                >
                  <v-col cols="9">
                    <Plotly
                      :data="usagePlots.seconds.data"
                      :layout="usagePlots.seconds.layout"
                      :display-mode-bar="false"
                    />
                  </v-col>
                  <v-col cols="3" class="pl-5">
                    <label>
                      {{ $i18n.t("COMPANY.hoursInRange") }}<br />
                      <span class="text-h5">
                        {{
                          (
                            usagePlots.seconds.data[0].y.reduce(
                              (a, b) => a + b,
                              0
                            ) /
                            60 /
                            60
                          ).toFixed(2)
                        }}
                      </span>
                    </label>
                    <br /><br />
                    <label>
                      {{ $i18n.t("COMPANY.rangeTotal") }}<br />
                      <span class="text-h5">
                        {{
                          (
                            (usagePlots.seconds.data[0].y.reduce(
                              (a, b) => a + b,
                              0
                            ) /
                              60 /
                              60) *
                            0.12
                          ).toFixed(2)
                        }}
                      </span>
                    </label>

                    <br /><br />
                    <label>
                      {{ $i18n.t("COMPANY.allTimeSeconds") }}<br />
                      <span class="text-h5">
                        {{ (company.billedSeconds / 1000).toFixed(1) + "k" }}
                      </span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters class="align-center" v-show="!nodata">
                  <v-col cols="9">
                    <Plotly
                      :data="usagePlots.storage.data"
                      :layout="usagePlots.storage.layout"
                      :display-mode-bar="false"
                    />
                  </v-col>
                  <v-col cols="3" class="pl-5">
                    <label>
                      {{ $i18n.t("COMPANY.storage") }}<br />
                      <span class="text-h5">
                        {{
                          formatBytes(
                            usagePlots.storage.data[0].y.reduce(
                              (a, b) => a + b,
                              0
                            )
                          )
                        }}
                      </span>
                    </label>
                    <br /><br />

                    <label>
                      {{ $i18n.t("COMPANY.totalStored") }}<br />
                      <span class="text-h5">
                        {{ formatBytes(company.usedStorage) }}
                      </span>
                    </label>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12" style="text-align: end">
                    <v-btn text class="mr-1" @click="closeAdd">
                      {{ $i18n.t("GENERAL.close") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>

    <Delete
      v-model="dialogs.delete"
      @action="remove"
      :title="$i18n.t('DELETE.title')"
      :message="$i18n.t('DELETE.message')"
      :confirmation="$i18n.t('DELETE.confirmation')"
      @close="closeDelete"
    />
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Delete from "@/components/Delete.vue";

import useCallableFunction from "@/hooks/useCallableFunction";
import Company from "./../models/company";
import { Plotly } from "vue-plotly";

import moment from "moment";
import Swal from "sweetalert2";
import { useValidateUsageLimit } from "./../hooks/useValidateUsageLimit";
import router from "@/router";

export default {
  components: {
    Table,
    Delete,
    Plotly,
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    headers() {
      return [
        { text: this.$i18n.t("COMPANY.tableHeaders.id"), value: "id" },
        { text: this.$i18n.t("COMPANY.tableHeaders.name"), value: "name" },
        {
          text: this.$i18n.t("COMPANY.tableHeaders.country"),
          value: "country",
        },
        {
          text: this.$i18n.t("COMPANY.tableHeaders.description"),
          value: "description",
        },
      ];
    },
    headersToken() {
      return [
        { text: this.$i18n.t("COMPANY.tokenTableHeaders.name"), value: "name" },
        { text: this.$i18n.t("COMPANY.tokenTableHeaders.key"), value: "id" },
        {
          text: this.$i18n.t("COMPANY.tokenTableHeaders.expirationDate"),
          value: "expiration_date",
        },
        {
          text: this.$i18n.t("COMPANY.tokenTableHeaders.enabled"),
          value: "enabled",
        },
        {
          text: this.$i18n.t("COMPANY.tokenTableHeaders.testKey"),
          value: "test",
        },
      ];
    },
    companySettings() {
      return [
        {
          name: this.$i18n.t("COMPANY.generalTitle"),
          icon: "mdi-apps",
        },
        {
          name: this.$i18n.t("COMPANY.webhookTitle"),
          icon: "mdi-webhook",
        },
        {
          name: this.$i18n.t("COMPANY.apiToken"),
          icon: "mdi-send-lock",
        },
        /* { name: this.$i18n.t('COMPANY.APITEXT'), icon: "mdi-whatsapp" } */
        // {
        //   name: this.$i18n.t("COMPANY.keywords"),
        //   icon: "mdi-fountain-pen",
        // },
        {
          name: this.$i18n.t("COMPANY.stereoChannels"),
          icon: "mdi-headphones",
        },
      ];
    },
  },
  mounted() {
    this.validateUsageLimit();
  },
  data() {
    return {
      usageLimitReached: false,
      callback: {
        url: null,
        secret: null,
        enabled: false,
      },
      keyword: null,
      email: null,
      menu: false,
      tokenmenu: false,
      nodata: false,
      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      usage: {
        storage: [],
        seconds: [],
      },
      apiloading: false,
      apitoken: [],
      newtoken: {
        name: null,
        expiration_date: null,
      },
      today: moment().format("YYYY-MM-DD"),
      showCreation: false,
      audio: {
        inboundChannel: "Agent Right",
        outboundChannel: "Agent Left",
      },
      companies: [],
      tag: null,
      searchTag: "",
      search: "",
      itemsCountry: [
        { text: "Afghanistan", code: "AF" },
        { text: "Åland Islands", code: "AX" },
        { text: "Albania", code: "AL" },
        { text: "Algeria", code: "DZ" },
        { text: "American Samoa", code: "AS" },
        { text: "AndorrA", code: "AD" },
        { text: "Angola", code: "AO" },
        { text: "Anguilla", code: "AI" },
        { text: "Antarctica", code: "AQ" },
        { text: "Antigua and Barbuda", code: "AG" },
        { text: "Argentina", code: "AR" },
        { text: "Armenia", code: "AM" },
        { text: "Aruba", code: "AW" },
        { text: "Australia", code: "AU" },
        { text: "Austria", code: "AT" },
        { text: "Azerbaijan", code: "AZ" },
        { text: "Bahamas", code: "BS" },
        { text: "Bahrain", code: "BH" },
        { text: "Bangladesh", code: "BD" },
        { text: "Barbados", code: "BB" },
        { text: "Belarus", code: "BY" },
        { text: "Belgium", code: "BE" },
        { text: "Belize", code: "BZ" },
        { text: "Benin", code: "BJ" },
        { text: "Bermuda", code: "BM" },
        { text: "Bhutan", code: "BT" },
        { text: "Bolivia", code: "BO" },
        { text: "Bosnia and Herzegovina", code: "BA" },
        { text: "Botswana", code: "BW" },
        { text: "Bouvet Island", code: "BV" },
        { text: "Brazil", code: "BR" },
        { text: "British Indian Ocean Territory", code: "IO" },
        { text: "Brunei Darussalam", code: "BN" },
        { text: "Bulgaria", code: "BG" },
        { text: "Burkina Faso", code: "BF" },
        { text: "Burundi", code: "BI" },
        { text: "Cambodia", code: "KH" },
        { text: "Cameroon", code: "CM" },
        { text: "Canada", code: "CA" },
        { text: "Cape Verde", code: "CV" },
        { text: "Cayman Islands", code: "KY" },
        { text: "Central African Republic", code: "CF" },
        { text: "Chad", code: "TD" },
        { text: "Chile", code: "CL" },
        { text: "China", code: "CN" },
        { text: "Christmas Island", code: "CX" },
        { text: "Cocos (Keeling) Islands", code: "CC" },
        { text: "Colombia", code: "CO" },
        { text: "Comoros", code: "KM" },
        { text: "Congo", code: "CG" },
        { text: "Congo, The Democratic Republic of the", code: "CD" },
        { text: "Cook Islands", code: "CK" },
        { text: "Costa Rica", code: "CR" },
        { text: "Cote D'Ivoire", code: "CI" },
        { text: "Croatia", code: "HR" },
        { text: "Cuba", code: "CU" },
        { text: "Cyprus", code: "CY" },
        { text: "Czech Republic", code: "CZ" },
        { text: "Denmark", code: "DK" },
        { text: "Djibouti", code: "DJ" },
        { text: "Dominica", code: "DM" },
        { text: "Dominican Republic", code: "DO" },
        { text: "Ecuador", code: "EC" },
        { text: "Egypt", code: "EG" },
        { text: "El Salvador", code: "SV" },
        { text: "Equatorial Guinea", code: "GQ" },
        { text: "Eritrea", code: "ER" },
        { text: "Estonia", code: "EE" },
        { text: "Ethiopia", code: "ET" },
        { text: "Falkland Islands (Malvinas)", code: "FK" },
        { text: "Faroe Islands", code: "FO" },
        { text: "Fiji", code: "FJ" },
        { text: "Finland", code: "FI" },
        { text: "France", code: "FR" },
        { text: "French Guiana", code: "GF" },
        { text: "French Polynesia", code: "PF" },
        { text: "French Southern Territories", code: "TF" },
        { text: "Gabon", code: "GA" },
        { text: "Gambia", code: "GM" },
        { text: "Georgia", code: "GE" },
        { text: "Germany", code: "DE" },
        { text: "Ghana", code: "GH" },
        { text: "Gibraltar", code: "GI" },
        { text: "Greece", code: "GR" },
        { text: "Greenland", code: "GL" },
        { text: "Grenada", code: "GD" },
        { text: "Guadeloupe", code: "GP" },
        { text: "Guam", code: "GU" },
        { text: "Guatemala", code: "GT" },
        { text: "Guernsey", code: "GG" },
        { text: "Guinea", code: "GN" },
        { text: "Guinea-Bissau", code: "GW" },
        { text: "Guyana", code: "GY" },
        { text: "Haiti", code: "HT" },
        { text: "Heard Island and Mcdonald Islands", code: "HM" },
        { text: "Holy See (Vatican City State)", code: "VA" },
        { text: "Honduras", code: "HN" },
        { text: "Hong Kong", code: "HK" },
        { text: "Hungary", code: "HU" },
        { text: "Iceland", code: "IS" },
        { text: "India", code: "IN" },
        { text: "Indonesia", code: "ID" },
        { text: "Iran, Islamic Republic Of", code: "IR" },
        { text: "Iraq", code: "IQ" },
        { text: "Ireland", code: "IE" },
        { text: "Isle of Man", code: "IM" },
        { text: "Israel", code: "IL" },
        { text: "Italy", code: "IT" },
        { text: "Jamaica", code: "JM" },
        { text: "Japan", code: "JP" },
        { text: "Jersey", code: "JE" },
        { text: "Jordan", code: "JO" },
        { text: "Kazakhstan", code: "KZ" },
        { text: "Kenya", code: "KE" },
        { text: "Kiribati", code: "KI" },
        { text: "Korea, Democratic People'S Republic of", code: "KP" },
        { text: "Korea, Republic of", code: "KR" },
        { text: "Kuwait", code: "KW" },
        { text: "Kyrgyzstan", code: "KG" },
        { text: "Lao People'S Democratic Republic", code: "LA" },
        { text: "Latvia", code: "LV" },
        { text: "Lebanon", code: "LB" },
        { text: "Lesotho", code: "LS" },
        { text: "Liberia", code: "LR" },
        { text: "Libyan Arab Jamahiriya", code: "LY" },
        { text: "Liechtenstein", code: "LI" },
        { text: "Lithuania", code: "LT" },
        { text: "Luxembourg", code: "LU" },
        { text: "Macao", code: "MO" },
        { text: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
        { text: "Madagascar", code: "MG" },
        { text: "Malawi", code: "MW" },
        { text: "Malaysia", code: "MY" },
        { text: "Maldives", code: "MV" },
        { text: "Mali", code: "ML" },
        { text: "Malta", code: "MT" },
        { text: "Marshall Islands", code: "MH" },
        { text: "Martinique", code: "MQ" },
        { text: "Mauritania", code: "MR" },
        { text: "Mauritius", code: "MU" },
        { text: "Mayotte", code: "YT" },
        { text: "Mexico", code: "MX" },
        { text: "Micronesia, Federated States of", code: "FM" },
        { text: "Moldova, Republic of", code: "MD" },
        { text: "Monaco", code: "MC" },
        { text: "Mongolia", code: "MN" },
        { text: "Montserrat", code: "MS" },
        { text: "Morocco", code: "MA" },
        { text: "Mozambique", code: "MZ" },
        { text: "Myanmar", code: "MM" },
        { text: "Namibia", code: "NA" },
        { text: "Nauru", code: "NR" },
        { text: "Nepal", code: "NP" },
        { text: "Netherlands", code: "NL" },
        { text: "Netherlands Antilles", code: "AN" },
        { text: "New Caledonia", code: "NC" },
        { text: "New Zealand", code: "NZ" },
        { text: "Nicaragua", code: "NI" },
        { text: "Niger", code: "NE" },
        { text: "Nigeria", code: "NG" },
        { text: "Niue", code: "NU" },
        { text: "Norfolk Island", code: "NF" },
        { text: "Northern Mariana Islands", code: "MP" },
        { text: "Norway", code: "NO" },
        { text: "Oman", code: "OM" },
        { text: "Pakistan", code: "PK" },
        { text: "Palau", code: "PW" },
        { text: "Palestinian Territory, Occupied", code: "PS" },
        { text: "Panama", code: "PA" },
        { text: "Papua New Guinea", code: "PG" },
        { text: "Paraguay", code: "PY" },
        { text: "Peru", code: "PE" },
        { text: "Philippines", code: "PH" },
        { text: "Pitcairn", code: "PN" },
        { text: "Poland", code: "PL" },
        { text: "Portugal", code: "PT" },
        { text: "Puerto Rico", code: "PR" },
        { text: "Qatar", code: "QA" },
        { text: "Reunion", code: "RE" },
        { text: "Romania", code: "RO" },
        { text: "Russian Federation", code: "RU" },
        { text: "RWANDA", code: "RW" },
        { text: "Saint Helena", code: "SH" },
        { text: "Saint Kitts and Nevis", code: "KN" },
        { text: "Saint Lucia", code: "LC" },
        { text: "Saint Pierre and Miquelon", code: "PM" },
        { text: "Saint Vincent and the Grenadines", code: "VC" },
        { text: "Samoa", code: "WS" },
        { text: "San Marino", code: "SM" },
        { text: "Sao Tome and Principe", code: "ST" },
        { text: "Saudi Arabia", code: "SA" },
        { text: "Senegal", code: "SN" },
        { text: "Serbia and Montenegro", code: "CS" },
        { text: "Seychelles", code: "SC" },
        { text: "Sierra Leone", code: "SL" },
        { text: "Singapore", code: "SG" },
        { text: "Slovakia", code: "SK" },
        { text: "Slovenia", code: "SI" },
        { text: "Solomon Islands", code: "SB" },
        { text: "Somalia", code: "SO" },
        { text: "South Africa", code: "ZA" },
        { text: "South Georgia and the South Sandwich Islands", code: "GS" },
        { text: "Spain", code: "ES" },
        { text: "Sri Lanka", code: "LK" },
        { text: "Sudan", code: "SD" },
        { text: "Suritext", code: "SR" },
        { text: "Svalbard and Jan Mayen", code: "SJ" },
        { text: "Swaziland", code: "SZ" },
        { text: "Sweden", code: "SE" },
        { text: "Switzerland", code: "CH" },
        { text: "Syrian Arab Republic", code: "SY" },
        { text: "Taiwan, Province of China", code: "TW" },
        { text: "Tajikistan", code: "TJ" },
        { text: "Tanzania, United Republic of", code: "TZ" },
        { text: "Thailand", code: "TH" },
        { text: "Timor-Leste", code: "TL" },
        { text: "Togo", code: "TG" },
        { text: "Tokelau", code: "TK" },
        { text: "Tonga", code: "TO" },
        { text: "Trinidad and Tobago", code: "TT" },
        { text: "Tunisia", code: "TN" },
        { text: "Turkey", code: "TR" },
        { text: "Turkmenistan", code: "TM" },
        { text: "Turks and Caicos Islands", code: "TC" },
        { text: "Tuvalu", code: "TV" },
        { text: "Uganda", code: "UG" },
        { text: "Ukraine", code: "UA" },
        { text: "United Arab Emirates", code: "AE" },
        { text: "United Kingdom", code: "GB" },
        { text: "United States", code: "US" },
        { text: "United States Minor Outlying Islands", code: "UM" },
        { text: "Uruguay", code: "UY" },
        { text: "Uzbekistan", code: "UZ" },
        { text: "Vanuatu", code: "VU" },
        { text: "Venezuela", code: "VE" },
        { text: "Viet Nam", code: "VN" },
        { text: "Virgin Islands, British", code: "VG" },
        { text: "Virgin Islands, U.S.", code: "VI" },
        { text: "Wallis and Futuna", code: "WF" },
        { text: "Western Sahara", code: "EH" },
        { text: "Yemen", code: "YE" },
        { text: "Zambia", code: "ZM" },
        { text: "Zimbabwe", code: "ZW" },
      ],
      itemsTenant: [
        { text: "Brazil", id: 1 },
        { text: "USA", id: 2 },
        { text: "Canada", id: 3 },
        { text: "Hong Kong", id: 4 },
        { text: "Argentina", id: 5 },
        { text: "Mexico", id: 6 },
        { text: "Colombia", id: 7 },
        { text: "Spain", id: 9 },
        { text: "Peru", id: 10 },
        { text: "Chile", id: 11 },
        { text: "Uruguay", id: 12 },
      ],
      loading: false,
      buttonLoader: false,
      company: {},
      disableSave: false,
      rules: {
        required: (value) => {
          const validation = !!value;
          this.disableButton(validation);
          return validation || "Required";
        },
        minAgents: (v) => {
          const validation = v > 0;
          this.disableButton(validation);
          return validation || "Min 1";
        },
        notExist: (v) => {
          const validation = this.validateExist(v);
          this.disableButton(validation);
          return validation || "Already exist";
        },
        positiveValues: (v) => {
          const regex =
            /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;
          const validation = !v || regex.test(v.toString());
          this.disableButton(validation);
          return validation || "Only positive numbers are accepted";
        },
      },
      dialogs: {
        delete: false,
        add: false,
        save: false,
      },
      usagePlots: {
        seconds: {
          data: [
            {
              x: [],
              y: [],
              type: "lines",
            },
          ],
          layout: {
            title: "Seconds",
            height: 250,
            margin: {
              l: 50,
              r: 0,
              b: 50,
              t: 70,
              pad: 4,
            },
          },
        },
        storage: {
          data: [
            {
              x: [],
              y: [],
              type: "lines",
            },
          ],
          layout: {
            title: "Storage (bytes)",
            height: 250,
            margin: {
              l: 50,
              r: 0,
              b: 50,
              t: 70,
              pad: 4,
            },
          },
        },
      },
    };
  },
  created() {
    this.getCompanies();
    if (this.$store.state.Auth.token.role === "superuser") {
      this.companySettings.push({
        name: this.$i18n.t("COMPANY.USAGE"),
        icon: "mdi-database",
      });
    }
  },
  methods: {
    disableButton(validation) {
      if (!validation) this.disableSave = true;
      else this.disableSave = false;
    },
    getReport() {
      this.usagePlots.seconds.data[0].y = [];
      this.usagePlots.seconds.data[0].x = [];
      this.usagePlots.storage.data[0].y = [];
      this.usagePlots.storage.data[0].x = [];

      const params = {
        from: this.dates[0],
        to: this.dates[1],
        company: this.company.id,
        resample: "D",
        date_format: "%Y-%m-%d",
      };
      const addMessage = useCallableFunction("transcriptionHistory");
      addMessage(params)
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          const data = result.data;
          // const sanitizedMessage = data.text;

          if (data.length > 0) {
            this.nodata = false;
          } else {
            this.nodata = true;
          }

          const secondsY = [];
          const storageY = [];
          const dateX = [];

          result.data.forEach((elem) => {
            secondsY.push(+elem.seconds);
            storageY.push(+elem.storage);
            dateX.push(moment(elem.date.value).utc().format("YYYY-MM-DD"));
          });

          this.usagePlots.seconds.data[0].y = secondsY;

          this.usagePlots.seconds.data[0].x = dateX;

          this.usagePlots.storage.data[0].y = storageY;

          this.usagePlots.storage.data[0].x = dateX;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    validateExist(company) {
      if (this.dialogs.save && !this.dialogs.add === false) {
        return true;
      }

      let exist = false;

      if (this.companies && this.companies.length > 0) {
        this.companies.forEach((element) => {
          if (company === element.id) {
            exist = true;
          }
        });
      }

      if (exist) {
        return false;
      } else {
        return true;
      }
    },
    async getCompanies() {
      try {
        const allCompanies = await Company.getAll();
        this.companies = allCompanies.map((company) => ({
          ...company,
          secondsLimitPerCompany: company.secondsLimitPerCompany / 60,
        }));
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching companies",
        });
      }
    },
    async remove() {
      try {
        await Company.remove(this.company);
        await this.getCompanies();
        this.closeDelete();
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "DELETE",
          text: "Company removed successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "DELETE",
          text: "Error in remove company",
        });
        console.log(error);
      }
    },
    async add() {
      if (this.$refs["add-form"].validate()) {
        try {
          const company = new Company(this.company);
          const user = this.$store.state.Auth.token.claims.user_id;
          await Company.save(company, user);
          await Company.saveConfiguration("callback", company, {
            url: null,
            secret: null,
            enabled: false,
          });
          await Company.saveConfiguration("audio", company, {
            inboundChannel: 1,
            outboundChannel: 0,
          });
          this.getCompanies();
          this.closeAdd();
          this.company = {};
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: "Company added successfully",
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "Error in add company",
          });
        }
      }
    },
    async save() {
      if (this.$refs["add-form"].validate()) {
        try {
          const user = this.$store.state.Auth.token.claims.user_id;
          await Company.save(this.company, user);
          this.getCompanies();

          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "SAVE",
            text: "Company updated successfully, page will now reload",
          });
          setTimeout(() => router.go(), 3000);
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "SAVE",
            text: "Error in save company",
          });
        }
      }
    },
    closeDelete() {
      this.dialogs.delete = !this.dialogs.delete;
    },
    closeAdd() {
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.$refs["add-form"].reset();
    },
    showDelete(company) {
      this.dialogs.delete = !this.dialogs.delete;
      this.company = company;
    },
    showAdd() {
      this.dialogs.add = !this.dialogs.add;
    },
    showSave(company) {
      this.company = new Company(company);

      const totalGB = this.company.usedStorage / 1024 / 1024 / 1024;
      this.totalGB = totalGB;

      this.dialogs.add = true;
      this.dialogs.save = true;
      this.getConfigurations();
    },
    async getConfigurations() {
      this.getReport();
      this.getCallback();
      this.getAudioSettings();
      this.getTokens();
    },
    async getCallback() {
      try {
        this.callback = await Company.getConfiguration(
          "callback",
          this.company
        );
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching callback",
        });
      }
    },
    async getAudioSettings() {
      try {
        const audio = await Company.getConfiguration("audio", this.company);
        if (audio.inboundChannel === 0) {
          this.audio.inboundChannel = "Agent Left";
        } else {
          this.audio.inboundChannel = "Agent Right";
        }

        if (audio.outboundChannel === 0) {
          this.audio.outboundChannel = "Agent Left";
        } else {
          this.audio.outboundChannel = "Agent Right";
        }
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching audio settings",
        });
      }
    },
    showTokenCreation() {
      this.showCreation = !this.showCreation;
      this.newtoken = {
        name: null,
        expiration_date: null,
      };
    },
    async createToken() {
      try {
        const token = await Company.createToken(this.company.id, this.newtoken);
        this.apitoken = this.apitoken.concat(token);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "SAVE",
          text: "New token created successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "SAVE",
          text: "An error ocurred while creating new token" + error,
        });
      }
    },
    async updateTokens() {
      try {
        await Company.updateTokens(this.apitoken, this.company.id);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "SAVE",
          text: "Changes applied successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error saving changes in tokens " + error,
        });
      }
    },
    async getTokens() {
      try {
        this.apiloading = true;
        this.apitoken = [];
        this.apitoken = await Company.getTokens(this.company.id);
        this.apiloading = false;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching company tokens",
        });
      }
    },
    async testToken(token) {
      try {
        const testToken = useCallableFunction("api/testToken", {
          timeout: 540000,
        });
        const obj = {
          token,
        };
        await testToken(obj);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "GET",
          text: "Valid Token",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Invalid Token",
        });
      }
    },
    addKeyword() {
      if (this.keyword) {
        this.company.keywords.push(this.keyword);
        this.keyword = null;
      }
    },
    removeKeyword(word) {
      this.company.keywords = this.company.keywords.filter(
        (elem) => elem !== word
      );
    },
    addEmail() {
      if (this.email) {
        this.company.emailNotifications.push(this.email);
        this.email = null;
      }
    },
    removeEmail(email) {
      this.company.emailNotifications = this.company.emailNotifications.filter(
        (elem) => elem !== email
      );
    },
    filtered(dispositions) {
      return (dispositions || []).filter((disposition) => {
        return (
          disposition.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    filteredTag(tag) {
      return (tag || []).filter((tag) => {
        return tag.toLowerCase().indexOf(this.searchTag.toLowerCase()) > -1;
      });
    },
    async saveCallback() {
      try {
        const callback = { ...this.callback };
        await Company.saveConfiguration("callback", this.company, callback);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "SAVE",
          text: "Callback updated successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "SAVE",
          text: "Error in save callback",
        });
      }
    },
    async saveAudioSettings() {
      try {
        const audioSetting = { ...this.audio };
        const audio = {
          inboundChannel: 1,
          outboundChannel: 1,
        };
        if (audioSetting.inboundChannel === "Agent Left") {
          audio.inboundChannel = 0;
        } else {
          audio.inboundChannel = 1;
        }

        if (audioSetting.outboundChannel === "Agent Left") {
          audio.outboundChannel = 0;
        } else {
          audio.outboundChannel = 1;
        }

        await Company.saveConfiguration("audio", this.company, audio);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "SAVE",
          text: "Audio settings updated successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "SAVE",
          text: "Error saving audio settings",
        });
      }
    },
    onAllAdminsActive(show) {
      if (show) {
        Swal.fire({
          title: this.$i18n.t("COMPANY.allAdminsWarningTitle"),
          text: this.$i18n.t("COMPANY.allAdminsWarningText"),
          icon: "info",
        });
      }
    },
    async validateUsageLimit() {
      try {
        const { limitReached } = await useValidateUsageLimit();
        if (limitReached) this.usageLimitReached = true;
      } catch (e) {
        console.error(e);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error while trying to validate monthly seconds billed",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.list__overflow {
  max-height: 300px;
  overflow-y: auto;
}

.save-card {
  border-top: 3px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}

#campaign > .v-tabs-bar {
  background-image: radial-gradient(
    circle 386px at 0% 64.1%,
    var(--v-primary-base) 0%,
    var(--v-primary-darken1) 100.7%
  ) !important;
  border-top-right-radius: 0px !important;
}

.v-stepper__label {
  opacity: 0.8 !important;
  font-size: 14px !important;
}

.v-stepper {
  border: none !important;
  box-shadow: none !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-stepper__content {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.legend-unlimited-usage {
  display: flex;
  justify-content: space-evenly;
}

.legend-usage-reached {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  color: white;
  background: red;
  border-radius: 28px;
  margin-bottom: 10px;
}
</style>
